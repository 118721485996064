<template>
  <div class="content_box theme_bg radar-box">
    <tab-menu v-model="activeKey" @change="activeSelFun()">
      <tab-pane label="雷达回波" name="1" style="position: relative">
        <van-row>
          <van-col span="24">
            <van-row>
              <van-col span="24" class="satellite-img">
                <img :src="Ldhbimg" alt="" @click="showLgImg(Ldhbimg)" />
              </van-col>
              <van-col span="24">
                <time-axis class="time-axis" ref="TimeAxis" :listData="LdhbList" @change="LdhbSelItem"></time-axis>
              </van-col>
              <van-col span="24" class="Description">
                <p class="title">雷达回波图说明</p>
                <p class="text">
                  大气中的云、降水中的各种水汽凝结（凝华）物对气象雷达电磁波的后向散射产生的回波所形成的图像。主要的产品叫“基本反射率”，从蓝色到紫色以不同的色标表示回波
                  强度由大到小（10-70dBz),一般亮黄色域对应10毫米/小时左右的降水强度，暖红色区域对应20毫米/小时左右的降雨强度，并有可能出现短时雷雨大风、冰雹等强对流天气。
                </p>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </tab-pane>
      <tab-pane label="华北拼图" name="3" style="position: relative">
        <van-row>
          <van-col span="24">
            <van-row>
              <van-col span="24" class="satellite-img">
                <img :src="hbLdhbimg" alt="" @click="showLgImg(hbLdhbimg)" />
              </van-col>
              <van-col span="24">
                <time-axis class="time-axis" ref="TimeAxis1" :listData="hbLdhbList" @change="HbLdhbSelItem"></time-axis>
              </van-col>
              <van-col span="24" class="Description">
                <p class="title">华北拼图说明</p>
                <p class="text">
                  大气中的云、降水中的各种水汽凝结（凝华）物对气象雷达电磁波的后向散射产生的回波所形成的图像。主要的产品叫“基本反射率”，从蓝色到紫色以不同的色标表示回波
                  强度由大到小（10-70dBz),一般亮黄色域对应10毫米/小时左右的降水强度，暖红色区域对应20毫米/小时左右的降雨强度，并有可能出现短时雷雨大风、冰雹等强对流天气。
                </p>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </tab-pane>

      <tab-pane label="卫星云图" name="2">
        <van-row class="satellite_box">
          <van-col span="24" class="satellite-img">
            <img :src="Wxytimg" alt="" @click="showLgImg(Wxytimg)" />
            <!-- <img src="@/assets/newImg/R.png" alt=""> -->
          </van-col>
          <van-col span="24" class="time_axis">
            <r-progress
              :listData="WxytList"
              @change="WxytSelItem"
              ref="rProgress2"
            ></r-progress>
          </van-col>
          <van-col span="24" class="Description">
            <p class="title">卫星云图说明</p>
            <p class="text">
              气象卫星测量地表和云面发射的红外辐射，将这种辐射以图象表示就是红外云图。
            </p>
            <p class="text">
              在红外云图上物体的色调决定其自身的温度，物体温度越
              高，发射的辐射越大，色调越暗，由于大气的温度随高度是递
              减的，故云顶高而厚的云，其温度低呈白的色调，在这种云层
              覆盖和即将覆盖的地方，可能将出现降雨甚至雷雨大风、冰雹
              等强对流天气。
            </p>
          </van-col>
        </van-row>
      </tab-pane>
    </tab-menu>
  </div>
</template>

<script>
import tabMenu from "@/components/myTabs/tabMenu.vue";
import tabPane from "@/components/myTabs/tabPane.vue";
import rProgress from "./children/rProgress.vue";
import TimeAxis from "./children/TimeAxis.vue";
import { ImagePreview, Toast } from "vant";
import { GetLdhbList, GetWxytList,GetLdhbList_HuaBei } from "./api";
var district = null;

export default {
  components: {
    tabMenu,
    tabPane,
    rProgress,
    TimeAxis,
  },
  name: "radarChart",
  data() {
    return {
      activeKey: "1",
      hbLdhbimg: "/image/20230630030000000.png",
      hbLdhbList: [],
      Ldhbimg: "/image/SEVP_AOC_RDCP_SLDAS3_ECREF_AZ9310_L88_PI_20230630030000000.png",
      LdhbList: [],
      Wxytimg: "/image/SEVP_NSMC_WXBL_FY4A_ETCC_ACHN_LNO_PY_20230630024500000.jpg",
      WxytList: [],
      itemDateX: "",
      radar: {
        station: "Z9452",
        type: "R",
      },
    };
  },
  created() {
    this.GetLdhbListFun();
    this.GetWxytListFun();
    this.GethbLdhbListFun();
  },
  mounted() {
    // setTimeout(() => {
    //   this.mapCreat();
    // }, 300);
  },
  methods: {
    // 初始化地图
    mapCreat() {
      tdMap.map = new AMap.Map("tdMap", {
        mapStyle: "amap://styles/244457199a201ce7e1a2880f00e1bef7", //设置地图的显示样式
        // mapStyle: "amap://styles/2652f9820f5b01731f3a45c2cedb2da4", //设置地图的显示样式
        zoom: 6,
        center: [mapCenter[0], mapCenter[1]],
        zoomEnable: true,
        scrollWheel: true,
        dragEnable: true,
      });
      // 来源于index.html中引入的 ./public/js/map/gdMap.js
      tdMap.addAreaLine(230200, 3);
    },

    // 放大图片
    showLgImg(url) {
      ImagePreview({
        images: [url],
        closeable: true,
      });
    },

    //  获取华北拼图
    async GethbLdhbListFun() {
      this.$loading.show();
      const res = await GetLdhbList_HuaBei(this.radar);
      this.$loading.hide();
      if (!Array.isArray(res.data) || res.data.length == 0 ) return Toast.fail("暂无数据");
      this.hbLdhbList = res.data.reverse();
      setTimeout(() => {
        this.HbLdhbSelItem(this.hbLdhbList[this.hbLdhbList.length-1]);
      }, 1000);
      this.$refs.TimeAxis1.toothListFun(res.data);
    },

    // 华北回波选中
    HbLdhbSelItem(item) {
      this.hbLdhbimg = item.ImgUrl;
    },

    //  获取雷达数据
    async GetLdhbListFun() {
      this.$loading.show();
      const res = await GetLdhbList(this.radar);
      this.$loading.hide();
      if (!Array.isArray(res.data) || res.data.length == 0 ) return Toast.fail("暂无数据");
      this.LdhbList = res.data.reverse();
      setTimeout(() => {
        this.LdhbSelItem(this.LdhbList[this.LdhbList.length-1]);
      }, 1000);
      this.$refs.TimeAxis.toothListFun(res.data);
    },

    // 雷达回波选中
    LdhbSelItem(item) {
      this.Ldhbimg = item.ImgUrl;
    },

    //  获取卫星云图
    async GetWxytListFun() {
      const res = await GetWxytList();
      if(!Array.isArray(res.data) || res.data.length == 0 ) return Toast.fail("暂无数据");
      this.WxytList = res.data;
      this.$refs.rProgress2.toothListFun(res.data);
    },

    // 卫星云图选中
    WxytSelItem(item) {
      this.Wxytimg = item.ImgUrl;
    },
    /**清除覆盖物 */
    cleanMapLayerr(layer) {
      tdMap.map.remove(layer); //清除所有覆盖物
    },
  },

  filters: {},
};
</script>


<style lang="scss" scoped>
.my_gdmap {
  width: 100%;
  height: calc(100vh - 35px);
}

.ldhb_img {
  width: 50px;
  background-color: rgba($color: #fff, $alpha: 0.6);
  position: absolute;
  right: 2%;
  bottom: 30%;
}

.time-axis {
  margin-top: 10px;
  width: 100%;
  // position: absolute;
  // position: fixed;
  // bottom: 0%;
  background-color: #1b315c;
  // background-color: #3C9A8D;
}

.options_box {
  position: absolute;
  top: 0%;
  width: 100%;
  box-sizing: border-box;
  height: 120px;
  background-color: #1b315c;
  // background-color: #3C9A8D;
  padding: 10px;

  .op_item {
    // display: flex;
    color: #fff;
    margin-bottom: 10px;

    > p {
      margin: 0;
      color: #4e97ec;
      // color:#fff;
      white-space: nowrap;
    }

    > p::before {
      content: " ";
      display: inline-block;
      width: 3px;
      height: 15px;
      background-color: #4e97ec;
      margin-right: 10px;
      transform: translateY(20%);
    }
  }

  ::v-deep .van-radio-group {
    display: flex;
    flex-wrap: wrap;

    .van-radio {
      margin: 5px;
    }
  }

  ::v-deep .van-radio__label {
    color: #fff;
    font-size: 12px;
  }
}

.radar-box {
  padding-top: 10px;
  background-image: none;
  background-color: #1b315c;
  // background-color: #3C9A8D;
}

.satellite-img {
  height: 250px;
  overflow: auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.Description {
  height: 250px;
  background-color: rgba($color: #000000, $alpha: 0.2);
  border-radius: 2px;
  padding: 0 10px;

  .text {
    font-size: 13px;
    text-indent: 24px;
    line-height: 22px;
  }

  .title {
    position: relative;
    text-align: center;
    font-size: 16px;
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.5);
    padding-bottom: 5px;
  }

  .title::after {
    content: " ";
    position: absolute;
    left: 50%;
    bottom: -2px;
    transform: translateX(-50%);
    width: 120px;
    height: 1px;
    border-bottom: 3px solid #308bca;
  }
}
.satellite_box {
  padding: 5px;
  box-sizing: border-box;

  .time_axis {
    // height: 150px;
  }
}
</style>